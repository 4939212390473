/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
    margin-top: 2.4rem;
}
.Toastify__toast-container--top-center {
    margin-top: 2.4rem;
}
.Toastify__toast-container--top-right {
    margin-top: 2.4rem;
}
.Toastify__toast-container--bottom-left {
    margin-bottom: 2.4rem;
}
.Toastify__toast-container--bottom-center {
    margin-bottom: 2.4rem;
}
.Toastify__toast-container--bottom-right {
    margin-bottom: 2.4rem;
}

/** Classes for the displayed toast **/
.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: #03a9f4 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #388e3c !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: #ff5722 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: #e53935 !important;
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
}





